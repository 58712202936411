import { Component } from 'react';
import PropTypes from 'prop-types';

import { perfMarkStart } from './performance';

export type PerformanceStartProps = {
	subject: string;
	subjectId?: string;
	markBeforeMount?: boolean;
};

export class PerformanceStart extends Component<PerformanceStartProps> {
	static propTypes = {
		subject: PropTypes.string.isRequired,
		subjectId: PropTypes.string,
		markBeforeMount: PropTypes.bool,
	};

	static defaultProps = {
		subjectId: '',
		markBeforeMount: false,
	};

	constructor(props: PerformanceStartProps) {
		super(props);

		if (props.markBeforeMount) {
			const { subject, subjectId } = this.props;

			perfMarkStart({ subject, subjectId });
		}
	}

	componentDidMount() {
		const { markBeforeMount, subject, subjectId } = this.props;

		if (!markBeforeMount) {
			perfMarkStart({ subject, subjectId });
		}
	}

	render() {
		return null;
	}
}
