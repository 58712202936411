import type { ComponentType } from 'react';
import React, { Component } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { FlagsStateContainer } from './FlagsStateContainer';

export type WithFlagsProps = {
	flags: FlagsStateContainer;
};

type PropsWithoutFlag<TProps> = Omit<TProps, 'flags'> extends never ? {} : Omit<TProps, 'flags'>;

export function withFlags<TProps>(
	InnerComponent: ComponentType<TProps & WithFlagsProps>,
): ComponentType<PropsWithoutFlag<TProps>> {
	return class WithFlags extends Component<PropsWithoutFlag<TProps>> {
		static displayName = `withFlags(${InnerComponent.displayName || InnerComponent.name})`;

		render() {
			return (
				<Subscribe to={[FlagsStateContainer]}>
					{(flags: FlagsStateContainer) => (
						<InnerComponent {...(this.props as any)} flags={flags} />
					)}
				</Subscribe>
			);
		}
	};
}
