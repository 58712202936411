import React from 'react';

import LegacyWatchedIcon from '@atlaskit/icon/glyph/watch-filled';
import LegacyUnwatchedIcon from '@atlaskit/icon/glyph/watch';

import WatchedIcon from '@atlaskit/icon/core/eye-open-filled';
import UnwatchedIcon from '@atlaskit/icon/core/eye-open';

type WatchIconProps = {
	isWatched: boolean;
	size?: 'small' | 'medium';
	legacy?: boolean;
};

export const WatchIcon = ({ isWatched, size = 'medium', legacy = false }: WatchIconProps) => {
	if (legacy) {
		return isWatched ? (
			<LegacyWatchedIcon label="" size={size} />
		) : (
			<LegacyUnwatchedIcon label="" size={size} />
		);
	}

	return isWatched ? (
		<WatchedIcon label="" color="currentColor" LEGACY_size={size} />
	) : (
		<UnwatchedIcon label="" color="currentColor" LEGACY_size={size} />
	);
};
