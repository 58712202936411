export const PERFORMANCE_SUBJECT_comments = 'comments';
export const PERFORMANCE_SUBJECT_commentsFabric = 'comments_fabric';
export const PERFORMANCE_SUBJECT_contentByline = 'content-byline';
export const PERFORMANCE_SUBJECT_contentHeader = 'content-header';
export const PERFORMANCE_SUBJECT_contentTitle = 'content-title';
export const PERFORMANCE_SUBJECT_contentTools = 'content-tools';
export const PERFORMANCE_SUBJECT_editButton = 'edit-button';
export const PERFORMANCE_SUBJECT_fabricRenderer = 'fabric-renderer';
export const PERFORMANCE_SUBJECT_favouriteButton = 'favourite-button';
export const PERFORMANCE_SUBJECT_labels = 'labels';
export const PERFORMANCE_SUBJECT_likes = 'likes';
export const PERFORMANCE_SUBJECT_linkToPage = 'link-to-page';
export const PERFORMANCE_SUBJECT_mainLayout = 'main-layout';
export const PERFORMANCE_SUBJECT_manageWatcherDialog = 'manage-watcher-dialog';
export const PERFORMANCE_SUBJECT_appNavigation = 'app-navigation';
export const PERFORMANCE_SUBJECT_navigation = 'Navigation';
export const PERFORMANCE_SUBJECT_navigationFMP = 'navigation-fmp';
export const PERFORMANCE_SUBJECT_sideNavigationFMP = 'side-navigation-fmp';
export const PERFORMANCE_SUBJECT_navigationView = 'NavigationView';
export const PERFORMANCE_SUBJECT_recentDropdown = 'recent-dropdown';
export const PERFORMANCE_SUBJECT_resolvedInlineCommentsDialog = 'resolved-inline-comments-dialog';
export const PERFORMANCE_SUBJECT_copyPageTree = 'copy-page-tree';
export const PERFORMANCE_SUBJECT_spaceDirectory = 'space-directory';
export const PERFORMANCE_SUBJECT_endOfPageRecommendation = 'end-of-page-recommendation';
